<template>
  <div class="v-text-field">
    <v-layout row>
      <v-flex xs12>
        <v-subheader
          light
          class="v-label v-label--active"
          :style="{'padding-left': (icon) ? '56px': '12px'}"
          style="margin-bottom: -30px; width:100%">
            {{ $t(label) }}
        </v-subheader>
      </v-flex>
    </v-layout>
    <v-layout row>
      <v-flex v-if="icon" style="width: 24px; margin-left: 12px;">
        <v-layout fill-height align-center justify-start>
          <v-icon>{{ icon }}</v-icon>
        </v-layout>
      </v-flex>
      <v-flex style="width: calc(100% - 72px)"
        :style="{'padding-left': (!icon) ? '24px': '0px'}">
        <v-radio-group
          v-model="value"
          class="ma-0 top-list"
          :readonly="readonly"
          :row="direction === 'row'"
          :column="direction === 'column'"
        >
          <v-radio
            class="ma-4"
            v-for="(item, index) in items"
            :key="index"
            :value="item.value"
            :label="$t(item.label)"
            :color="item.color"
          >
            <template slot="label" v-if="item.iconLabel">
              <v-icon x-large :color="item.color">
                {{ item.iconLabel }}
              </v-icon>
            </template>
            <template slot="label" v-else-if="item.imageLabel">
              <img :src="item.imageLabel" :width="item.width || '300'" />
            </template>
          </v-radio>
        </v-radio-group>
      </v-flex>
      <v-flex v-if="$t(description)" style="width: 24px; margin-right: 12px;">
        <v-layout fill-height align-center justify-end>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon class="ml-12" slot="activator" v-on="on">mdi-help-circle</v-icon>
            </template>
            <span>{{ $t(description) }}</span>
          </v-tooltip>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import baseTextInput from '@/mixins/baseTextInput';

export default {
  name: 'radioButtonField',
  mixins: [
    baseTextInput,
  ],
  props: {
    label: {
      type: String,
      default: 'fields.radioButton',
    },
    description: {
      type: String,
      default: 'fields.radioButtonTooltip',
    },
    direction: {
      type: String,
      default: 'column',
    },
    /**
     * Items need to be an array like
     *
     * {
     *   label: string,
     *   value: number|string
     *   color: string // optional
     *   icon: string // optional
     *   iconLabel: string // optional mdi icon to place as label
     *   imageLabel: string // optional image file url
     * }
     */
    items: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.top-list {
  padding-top: 0px !important;
  width: 100%;
  &.pl0 {
    padding-left: 0px !important;
  }
  .v-input__control {
    width: 100%;
  }
  .v-input__slot {
    margin-bottom: 0px !important;
  }
  .v-radio {
    margin-bottom: 0px !important;
  }
  .v-messages {
    display: none;
  }
}
</style>
